// initialStates.js
export const initialQuizState = {
  isProbono: false,
  user: {},
  slot: {},
  fileId: '',
  file: {},
  locationId: '',
  isAdult: '',
  tier: '',
  isStandardRate: null,
  rate: 0,
  firstName: '',
  email: '',
  tier: 'resident',
  goals: [],
  therapistGoals: [],
  isInsurance: null,
  coverage: [],
  pros: [
    // 'MtAdLaXGEIdxjqUR88orPkrDC6b2', // Samantha
    // 'MtAdLaXGEIdxjqUR88orPkrDC6b2', // Samantha
    // `BxxemuISXcWHl7nd8UdmA9Jp0iE3`, // Lauren
    // `m3OQ7hjApge8WLXQPGKFP8xBuZU2`, // Myles
  ],
  proSelected: {},
  serviceId: '',
  referrer: '',
  isPaidReferrer: false,
};

export const initialProState = {
  id: '',
  firstName: '',
  lastName: '',
  pronoun: '',
  gender: '',
  tel: '',
  type: '',
  role: '',
  bio: '',
  couple: null,
  trauma: null,
  img: '',
  isAccepting: null,
  education: {},
  modalities: [],
  specializations: [],
};

export const initialUserState = {
  name: '',
  email: '',
  phone: '',
};

export const initialQuizContext = {
  quiz: initialQuizState,
  setQuiz: () => {},
}
